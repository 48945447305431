import Nav from "./nav";
import Footer from "./footer";
import Link from "next/link";
import { useRouter } from "next/router";
import { useSession } from "./providers/session";
import FixedBanner from "./fixed-banner";
import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const blocked = [
  "/enroll/",
  "/sign-up/",
  "/sign-in/",
  "/account/",
  "/enrolment/",
  "/documents/",
  // "/for-business/",
];

const promoConfig = {
  online: {
    title: "",
    text: "",
  },
  other: {
    title: "",
    text: "",
  },
};

const collections = [
  {
    name: "30% Off VTCT",
    href: "/courses/vtct/",
    code: "CAREERUPGRADE",
    lead: "Nationally recognised qualifications",
    imageSrc:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F2JSq9yNyobEFxQfHuJhatg%2Fc3bdaefdeeeb929b13045aa3cf8b8997%2FIMG_2276.JPG&w=3840&q=75",
    imageAlt: "30% Off VTCT training courses",
  },
  {
    name: "50% Off CPD Courses",
    code: "NEWYEAR2024",
    href: "/courses/",
    lead: "Beauty, Aesthetics & Injectables",
    imageSrc:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F62MVX0hFBSEssx4ZkWb5GS%2Fd37304a9939a4266994a0908ebb5af3a%2FIMG_0458.jpg&w=3840&q=75",
    imageAlt: "50% Off CPD Courses",
  },
  {
    name: "50% Off Online Courses",
    code: "NEWYEAR2024",
    href: "/courses/elearning/",
    lead: "Train from home with our online courses",
    imageSrc:
      "https://www.cosmetic.college/_next/image/?url=https%3A%2F%2Fimages.ctfassets.net%2Feo68gvto3q0j%2F59rbWh4MVuoa5G8GQ3FoBV%2Feeb0cc1ea523629d12c29efdc415b3ed%2FUntitled_design__15_.png&w=3840&q=75",
    imageAlt: "50% Off Online Courses",
  },
];

export default function Layout({ siteData, children }) {
  const router = useRouter();
  const session = useSession();
  const [selectedCoursesCount, setSelectedCoursesCount] = useState(
    session.selectedCourses ? session.selectedCourses.length : 0
  );

  useEffect(() => {
    setTimeout(() => {
      setSelectedCoursesCount(
        session.selectedCourses ? session.selectedCourses.length : 0
      );
    }, 1000);
  }, [session.selectedCourses.length]);

  const pts = router.asPath.split("/").filter(Boolean);
  let promo = router.asPath === "/" || pts.length < 2;
  let online =
    router.asPath === "/courses/elearning/" ||
    router.asPath === "/courses/elearning";

  if (router.asPath.includes("/account")) {
    promo = false;
  }

  return (
    <>
      {/* <FixedBanner router={router} /> */}
      <div className="relative bg-white flex min-h-screen flex-col overflow-hidden">
        <Nav siteData={siteData} />
        {/* <p className="mt-24 lg:mt-0 text-white flex bg-brand-600 justify-center pt-2 pb-2 hover:text-gray-200 shadow overflow-hidden">
          <svg
            className="h-6 w-6 block"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          <a
            href={`tel:+44${siteData.fields.phoneNumber
              .replace(/^0+/, "")
              .replace(/\s/g, "")}`}
            className="flex"
          >
            <span className="text-base block ml-2">Call us now on </span>
            <span className="sr-only">Phone number</span>
            <span className="text-base block ml-1 underline">
              {siteData.fields.phoneNumber}
            </span>
          </a>
        </p> */}
        <main className="flex flex-col flex-1 w-full">{children}</main>
        <Footer siteData={siteData} />
        {/* {!router.asPath.includes("checkout") &&
          session &&
          session.selectedCourses.length > 0 && (
            <Link href="/checkout/"  className="flex">
              <div
                className={`fixed bottom-5 left-4 h-14 w-14 rounded-full text-white flex bg-brand-600 justify-center items-center hover:text-gray-200 ${
                  selectedCoursesCount !== session.selectedCourses.length
                    ? "bounce"
                    : ""
                }`}
              >
                {session.selectedCourses && session.selectedCourses.length && (
                  <div className="absolute top-0 right-0 w-5 h-5 bg-red-500 text-white rounded-full flex items-center justify-center text-sm font-semibold">
                    {session.selectedCourses.length}
                  </div>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
              </div>
            </Link>
          )} */}
      </div>
    </>
  );
}
