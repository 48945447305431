import { ComponentMap } from "components";

export default function Panel({ fields, sys, includes,  ...rest }) {
  let type = null;

  switch (fields.type) {
    case "Horizontal":
      type = (
        <ComponentMap.horizontalPanel
          fields={fields}
          sys={sys}
          includes={includes}
        />
      );
      break;
    case "Split Image":
      type = (
        <ComponentMap.splitPanel
          fields={fields}
          sys={sys}
          includes={includes}
          { ...rest}
        />
      );
      break;
    case "Full Centered":
      type = (
        <ComponentMap.centered fields={fields} sys={sys} includes={includes} />
      );
      break;
    case "Justified":
      type = (
        <ComponentMap.justified fields={fields} sys={sys} includes={includes} />
      );
      break;
    case "Stacked":
      type = (
        <ComponentMap.stacked fields={fields} sys={sys} includes={includes} />
      );
      break;
    default:
      type = null;
  }

  return type;
}
